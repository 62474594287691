<template>
  <div class="page-content content-area pt-40 pb-0">

    <div class="container">
      <TopBar></TopBar>
      <div class="navbar navbar-style-1" style="top: unset">
        <div class="navbar-inner">
          <a href="/" @click="$router.go(-1)" class="link back">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M0.439312 13.0606L5.75391 18.3752C6.04683 18.6682 6.43069 18.8146 6.81459 18.8146C7.1985 18.8146 7.58236 18.6682 7.87528 18.3752C8.46103 17.7894 8.46103 16.8397 7.87528 16.2539L5.12133 13.5H22.5C23.3284 13.5 24 12.8284 24 12C24 11.1716 23.3284 10.5 22.5 10.5H5.12133L7.87528 7.7461C8.46103 7.1603 8.46103 6.21057 7.87528 5.62477C7.28944 5.03898 6.33975 5.03898 5.75391 5.62477L0.439312 10.9394C-0.146437 11.5251 -0.146437 12.4749 0.439312 13.0606Z"
                  fill="black"/>
            </svg>
          </a>
          <div class="title">{{ category.title }}</div>
        </div>
      </div>

      <!-- Page Content -->
      <div class="page-content" style="padding-top: 1%">

        <!-- Search backdrop -->
        <div class="searchbar-backdrop"></div>

        <div class="container">
          <!-- Search -->
          <form data-search-container=".search-list" data-search-in=".item-title"
                class="searchbar searchbar-init search-box list-search-bx">
            <div class="searchbar-inner">
              <div class="searchbar-input-wrap">
                <input type="search" placeholder="Procurar"/>
                <i class="searchbar-icon">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M9.65925 19.3102C11.8044 19.3103 13.8882 18.5946 15.5806 17.2764L21.9653 23.6612C22.4423 24.1218 23.2023 24.1086 23.663 23.6316C24.1123 23.1664 24.1123 22.4288 23.663 21.9636L17.2782 15.5788C20.5491 11.3682 19.7874 5.30335 15.5769 2.03244C11.3663 -1.23846 5.30149 -0.476784 2.03058 3.73376C-1.24033 7.9443 -0.478646 14.0092 3.73189 17.2801C5.42702 18.597 7.51269 19.3113 9.65925 19.3102ZM4.52915 4.52732C7.36245 1.69396 11.9561 1.69391 14.7895 4.52721C17.6229 7.36052 17.6229 11.9542 14.7896 14.7876C11.9563 17.6209 7.36261 17.621 4.52925 14.7877C4.5292 14.7876 4.5292 14.7876 4.52915 14.7876C1.69584 11.9749 1.67915 7.39796 4.49181 4.56465C4.50424 4.55217 4.51667 4.53974 4.52915 4.52732Z"
                        fill="#C9C9C9"/>
                  </svg>
                </i>
              </div>
            </div>
          </form>
          <div class="list simple-list searchbar-not-found">
            <ul>
              <li>Sem resultados.</li>
            </ul>
          </div>
        </div>


        <!-- Products -->
        <div class="container">
          <div data-space-between="0" data-slides-per-view="auto" data-centered-slides="false"
               class="swiper swiper-init tabbar tab-style-2">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <a href="#tab-1" class="tab-link tab-link-active">Produtos</a>
              </div>
            </div>
          </div>
          <div class="list simple-list searchbar-not-found">
            <ul>
              <li>Sem resultados.</li>
            </ul>
          </div>
        </div>

        <div class="tabs-swipeable-wrap mt-30 tabs-height-auto">
          <div class="tabs">
            <div id="tab-1" class="tab tab-active">
              <div class="list search-list mt-0 mb-20 container searchbar-found item-list">
                <ul class="row" :style="cartItems.length !== 0 ? 'margin-bottom: 10%' : ''" style="gap:0.5em">
                  <li class="col-100 medium-50" v-for="item in products" :key="item.id">
                    <div class="wishlist-bx" style="box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05)">
                      <div class="dz-info">
                        <div class="dz-head">
                          <h6 class="item-title"><a href="/">{{ item.title }}</a></h6>
                          <ul class="tag-list">
                            <li><a href="/" @click="$router.push({ path: `/product/${ item.id }` })">descrição: {{
                                item.title
                              }}</a>
                            </li>
                          </ul>
                        </div>
                        <div class="dz-meta">
                          <ul>
                            <li class="price">{{ item.price }} €</li>
                            <div class="stepper stepper-init stepper-round"
                                 style="border: 1px solid #c4c4c4; height: 42px">
                              <div class="stepper-button-minus" style="border: 0"
                                   @click="removeFromCard(`${ item.id }`)"></div>
                              <div class="stepper-input-wrap" style="border: 0"><input
                                  style="font-size: 18px;color: #000;" type="text"
                                  :value="itemQuantity(`${ item.id }`)" min="0" max="100"
                                  step="1"
                                  readonly/>
                              </div>
                              <div class="stepper-button-plus" @click="addToCart(`${ item.id }`)"
                                   style="border: 0"></div>
                            </div>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <CreateOrder v-if="cartItems.length !== 0"></CreateOrder>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {useRoute} from 'vue-router';
import axios from "axios";
import TopBar from "@/components/TopBar";
import {mapGetters} from "vuex";
import CreateOrder from "@/components/createOrder";


export default {
  name: 'ProductsView',
  components: {CreateOrder, TopBar},
  data() {
    return {
      category: [],
      products: [],
      categoriesList: [],
    }
  },

  mounted() {
    this.setLocalStorageClearTimeout();
    this.loadCategory();
    this.loadProducts();
  },

  methods: {
    addToCart(id) {
      this.$store.commit('addToCard', this.products.find(i => i.id === id))
    },
    removeFromCard(id) {
      this.$store.commit('removeFromCard', this.products.find(i => i.id === id))
    },

    setLocalStorageClearTimeout() {
      setTimeout(() => {
        localStorage.clear();
        console.log('limpou');
      }, 600000);
    },

    async loadProducts() {
      const {id} = useRoute().params
      const regex = /[a-zA-Z0-9]/g;

      let productUrl = process.env.VUE_APP_SERVICE_URL + 'category/' + id + '/products';
      let productUrlRegex = productUrl.match(regex).join('');
      let cachedProductUrl = localStorage.getItem(productUrlRegex);

      if (cachedProductUrl) {
        this.products = JSON.parse(cachedProductUrl);
      } else {
        await axios.get(productUrl).then(response => {
          this.products = response.data.products;
          localStorage.setItem(productUrlRegex, JSON.stringify(response.data.products));
        }).catch(error => {
          // Handle error
          console.error(error);
        });
      }
    },

    async loadCategory() {
      const {id} = useRoute().params
      const regex = /[a-zA-Z0-9]/g;

      let categoryUrl = process.env.VUE_APP_SERVICE_URL + 'category/' + id;
      let categoryUrlRegex = categoryUrl.match(regex).join('');
      let cachedCategoryUrl = localStorage.getItem(categoryUrlRegex);

      if (cachedCategoryUrl) {
        this.category = JSON.parse(cachedCategoryUrl);
      } else {
        await axios.get(categoryUrl)
            .then(response => {
              this.category = response.data[0];
              localStorage.setItem(categoryUrlRegex, JSON.stringify(response.data[0]));
            })
            .catch(error => {
              // Handle error
              console.error(error);
            });
      }
    }
  },

  computed: {
    ...mapGetters(['getItemQuantity']),
    itemQuantity() {
      return (id) => this.getItemQuantity(this.products.find(i => i.id === id));
    },

    ...mapGetters(['getCartItems']),
    cartItems() {
      return this.getCartItems
    }
  }
};

</script>
