<template>
  <div class="page page-home page-current">

    <!-- Page Content -->
    <div class="page-content content-area pt-40 pb-0">
      <!-- Search backdrop -->
      <div class="searchbar-backdrop"></div>
      <div class="container">
        <TopBar></TopBar>
        <!-- Search -->
        <form data-search-container=".search-list" data-search-in=".item-title"
              class="searchbar searchbar-init search-box list-search-bx">
          <div class="searchbar-inner">
            <div class="searchbar-input-wrap">
              <input type="search" placeholder="Procurar"/>
              <i class="searchbar-icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M9.65925 19.3102C11.8044 19.3103 13.8882 18.5946 15.5806 17.2764L21.9653 23.6612C22.4423 24.1218 23.2023 24.1086 23.663 23.6316C24.1123 23.1664 24.1123 22.4288 23.663 21.9636L17.2782 15.5788C20.5491 11.3682 19.7874 5.30335 15.5769 2.03244C11.3663 -1.23846 5.30149 -0.476784 2.03058 3.73376C-1.24033 7.9443 -0.478646 14.0092 3.73189 17.2801C5.42702 18.597 7.51269 19.3113 9.65925 19.3102ZM4.52915 4.52732C7.36245 1.69396 11.9561 1.69391 14.7895 4.52721C17.6229 7.36052 17.6229 11.9542 14.7896 14.7876C11.9563 17.6209 7.36261 17.621 4.52925 14.7877C4.5292 14.7876 4.5292 14.7876 4.52915 14.7876C1.69584 11.9749 1.67915 7.39796 4.49181 4.56465C4.50424 4.55217 4.51667 4.53974 4.52915 4.52732Z"
                      fill="#C9C9C9"/>
                </svg>
              </i>
            </div>
          </div>
        </form>
        <div class="list simple-list searchbar-not-found">
          <ul>
            <li>Nothing found</li>
          </ul>
        </div>

        <!-- Products -->
        <div class="title-bar" style="margin-bottom: 0px"><h2 class="dz-title">Os mais procurados</h2></div>
        <div data-space-between="20" data-slides-per-view="auto" data-centered-slides="false"
             class="swiper swiper-init main-swiper mb-40 overlay-swiper1">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="prod in prodProList" :key="prod.id">
              <div class="card-overlay style-1 bg-primary"
                   style="display: flex; flex-wrap: wrap; align-content: center;" @click="addToCart(`${ prod.id }`)">
                <div class="dz-media">
                  <img :src="`${ prod.image }`" style="height: 140px" alt=""/>
                </div>
                <div class="dz-info">
                  <h5 class="item-title title"><a href="/item-details/">{{ prod.title }}</a></h5>
                  <div class="dz-meta">
                    <ul>
                      <li class="price">{{ prod.price }}<sup>€</sup></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Categories -->
        <div class="title-bar">
          <h4 class="dz-title">Categorias</h4>
        </div>
        <div data-space-between="20" data-slides-per-view="auto" data-centered-slides="false"
             class="swiper swiper-init mb-40 main-swiper categories-swiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="item in categoriesList" :key="item.id">
              <div class="categories-bx" style="align-items: center;">
                <div class="icon-bx">
                  <a href="/" @click="$router.push({ path: `/category/${ item.id }` })">
                    <img :src="`${ item.image }`" alt="" style="fill: red;">
                  </a>
                </div>
                <div class="dz-content">
                  <h5 class="title"><a href="/" @click="$router.push({ path: `/category/${ item.id }` })">{{
                      item.title
                    }}</a></h5>
                  <span class="menus text-primary">{{ getTotalText(item.total) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Featured Beverages -->
        <div class="title-bar">
          <h4 class="dz-title">Promoções</h4>
          <a href="/products/" class="button text-capitalize">Mais</a>
        </div>
        <div class="list search-list mt-0 mb-20 searchbar-found item-list">
          <ul class="row">

          </ul>
        </div>

      </div>
      <CreateOrder v-if="cartItems.length !== 0"></CreateOrder>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
import axios from "axios";
import TopBar from "@/components/TopBar";
import CreateOrder from "@/components/createOrder";
import {mapGetters} from "vuex";

const cachedCategories = localStorage.getItem('cachedCategories');
const cachedProducts = localStorage.getItem('cachedProducts');

export default {
  name: 'HomeView',
  components: {CreateOrder, TopBar},

  data() {
    return {
      categoriesList: [],
      prodProList: [],
      VUE_API: process.env.VUE_APP_SERVICE_URL
    }
  },

  computed: {
    ...mapGetters(['getCartItems']),
    cartItems() {
      return this.getCartItems
    }
  },

  methods: {
    getTotalText(total) {
      return total > 1 ? `${total} produtos` : `${total} produto`;
    },

    addToCart(id) {
      this.$store.commit('addToCard', this.prodProList.find(i => i.id === id))
    },
  },

  async mounted() {
    if (cachedCategories) {
      this.categoriesList = JSON.parse(cachedCategories);
    } else {
      await axios.get(process.env.VUE_APP_SERVICE_URL + 'category',).then(response => {
        localStorage.setItem('cachedCategories', JSON.stringify(response.data));
        this.categoriesList = response.data;
      }).catch(error => {
        // Handle error
        console.error(error);
      });
    }

    if (cachedProducts) {
      this.prodProList = JSON.parse(cachedProducts);
    } else {
      await axios.get(process.env.VUE_APP_SERVICE_URL + 'product/priority').then(response => {
        localStorage.setItem('cachedProducts', JSON.stringify(response.data));
        this.prodProList = response.data;
      }).catch(error => {
        // Handle error
        console.error(error);
      });
    }

    new Swiper('.swiper-init', {
      // Swiper configuration options
      spaceBetween: parseInt(this.$el.dataset.spaceBetween) || 20,
      slidesPerView: this.$el.dataset.slidesPerView || 'auto',
      centeredSlides: this.$el.dataset.centeredSlides === 'true'
    });
  }
}

</script>

<style lang="scss">

</style>
