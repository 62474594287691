import {createApp} from 'vue'
import App from './App.vue'
import router from './router/routes';
import store from './store/index';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faMugSaucer} from '@fortawesome/free-solid-svg-icons';

library.add(faMugSaucer);

const app = createApp(App);

app.use(router)
app.use(store)
app.component('font-awesome-icon', FontAwesomeIcon);
app.mount('#app')



