<template>
  <div class="page-content content-area pt-40 pb-0">
    <div class="container">
      <TopBar></TopBar>
      <div class="navbar navbar-style-1">
        <div class="navbar-inner">
          <a href="/" @click="$router.go(-1)" class="link back">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M0.439312 13.0606L5.75391 18.3752C6.04683 18.6682 6.43069 18.8146 6.81459 18.8146C7.1985 18.8146 7.58236 18.6682 7.87528 18.3752C8.46103 17.7894 8.46103 16.8397 7.87528 16.2539L5.12133 13.5H22.5C23.3284 13.5 24 12.8284 24 12C24 11.1716 23.3284 10.5 22.5 10.5H5.12133L7.87528 7.7461C8.46103 7.1603 8.46103 6.21057 7.87528 5.62477C7.28944 5.03898 6.33975 5.03898 5.75391 5.62477L0.439312 10.9394C-0.146437 11.5251 -0.146437 12.4749 0.439312 13.0606Z"
                  fill="black"/>
            </svg>
          </a>
          <div class="title">Finalizar pedido</div>
          <div class="right">
            <a href="#" class="link panel-open" data-panel="left">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M14 12C14 10.8955 13.1046 10 12 10C10.8954 10 10 10.8955 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12Z"
                    fill="black"/>
                <path
                    d="M14 20C14 18.8954 13.1046 18 12 18C10.8954 18 10 18.8954 10 20C10 21.1045 10.8954 22 12 22C13.1046 22 14 21.1045 14 20Z"
                    fill="black"/>
                <path
                    d="M14 4C14 2.89544 13.1046 2.00001 12 2.00001C10.8954 2.00001 10 2.89544 10 4C10 5.10457 10.8954 6 12 6C13.1046 6 14 5.10457 14 4Z"
                    fill="black"/>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div class="page-content" style="padding-top: 0%">
        <div class="sticky-top bg-white pb-20">
          <ul class="nav-wizard container" style="padding: 0px 0 15px">
            <li>
            </li>
            <li>
              <a class="nav-link active" href="/checkout-payment-method/">
                <span></span>
              </a>
            </li>
            <li>
            </li>
          </ul>
        </div>
        <div class="container">
          <div class="list accordion-list dz-accordion dz-list">
            <ul>
              <li class="accordion-item accordion-item-opened">
                <a href="#" class="item-link item-content">
                  <div class="item-inner">
                    <div class="item-title">MbWay</div>
                  </div>
                </a>
                <div class="accordion-item-content">
                  <div class="item-content-inner">
                    <form class="form-elements">
                      <div class="list">
                        <ul class="row">
                          <li class="item-content item-input col-100">
                            <div class="item-inner">
                              <div class="item-title item-label">Número de telemóvel</div>
                              <div class="item-input-wrap">
                                <!-- TODO:: verificar o required -->
                                <input type="number" placeholder="" maxlength="9" minlength="9" class="form-control"/>
                              </div>
                            </div>
                          </li>
                          <li class="item-content item-input col-100 item-input-with-value">
                            <div class="item-inner">
                              <div class="item-title item-label">Nome</div>
                              <div class="item-input-wrap">
                                <input type="text" class="form-control"/>
                              </div>
                            </div>
                          </li>
                          <li class="item-content item-input col-100 item-input-with-value">
                            <div class="item-inner">
                              <div class="item-title item-label">Número de identificação fiscal</div>
                              <!-- TODO:: validar nif introduzido -->
                              <div class="item-input-wrap">
                                <input type="number" placeholder="" maxlength="9" minlength="9" class="form-control"/>
                              </div>
                            </div>
                          </li>
                          <li class="item-content item-input col-100 item-input-with-value">
                            <div class="item-inner">
                              <div class="item-title item-label">E-mail</div>
                              <div class="item-input-wrap">
                                <input type="email" id="email" name="email">
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </form>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="checkout-total">
            <p class="title">Total a pagar</p>
            <h2 class="checkout-total">$158.0</h2>
          </div>
        </div>
      </div>
      <div class="toolbar toolbar-bottom footer-button padding container">
        <a href="/checkout-shipping-address/" class="button-large button rounded-lg btn-icon button-fill">Pagar <i
            class="fas fa-caret-right"></i></a>
      </div>
    </div>
  </div>
</template>

<script>
import TopBar from "@/components/TopBar";
import {mapGetters} from "vuex";

const items = []

export default {
  name: 'ShoppingCart',
  components: {TopBar},
  data() {
    return {
      shopList: items
    }
  },

  computed: {
    ...mapGetters(['getCartItems']),
    cartItems() {
      return this.getCartItems
    },
    ...mapGetters(['getItemQuantity']),
    itemQuantity() {
      return (id) => this.getItemQuantity(this.cartItems.find(i => i.id === id));
    },
  },

  mounted() {

  },

  methods: {
    addToCart(id) {
      this.$store.commit('addToCard', this.cartItems.find(i => i.id === id))
    },
    removeFromCard(id) {
      this.$store.commit('removeFromCard', this.cartItems.find(i => i.id === id))
    },
    deleteFromCard(id) {
      this.$store.commit('deleteFromCard', this.cartItems.find(i => i.id === id))
    }
  }
};

</script>


