<template>
  <div class="toolbar toolbar-bottom container footer-button padding" style="position: fixed;">
    <a href="/" @click="$router.push({ path: `/checkout` })" class="button-large button button-fill rounded-xl">Finalizar pedido<span
        style="opacity: 0.5;margin-left: 10px;">{{ orderPrice.toFixed(2).replace('.', ',') + ' €' }}</span></a>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

const items = []
export default {
  name: 'createOrder',
  data() {
    return {
      shopList: items
    }
  },

  computed: {
    ...mapGetters(['getCartItems', 'getTotalPrice']),
    cartItems() {
      return this.getCartItems
    },
    orderPrice() {
      return this.getTotalPrice
    },
  },

  mounted() {

  },

  methods: {}
};

</script>
