<script>
export default {
  mounted() {
    this.$store.commit('updateCartFromLocalStorage');
  }
}
</script>

<template>
  <RouterView/>
</template>
